<template>
  <section >
    <h2 class="titulo-links-escolha">EDITAR ESCOLA DO DIRETOR</h2>

    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12 lg:col-12">
            <h4>
              <strong>Nome do Diretor(a): {{diretor.nome}}</strong>
            </h4>
          </div>
          <div class="field col-12 md:col-4 lg:col-3">
            <label>Selecione a Escola</label>
            <select class="p-inputtext p-component" v-model="escola"
              style="appearance: revert !important;width: 100% !important;" >
                <option v-for="n in escolasALL" :key="n.id" :value="n.id" >{{n.nome}}</option>
            </select>
          </div>
        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left"
               @click="this.$router.push({ name: 'coordenacao-servidores', params: { pg: this.pg} })" />

          <pm-Button   class="p-button-success"
             label="Editar" @click="editar()"
             v-if="escola"
             icon="pi pi-check" iconPos="right"
          />
        </div>
      </template>
    </pm-Card>
  </section >
</template>

<script>

import { Calendario } from "@/class/calendario.js";
import { Servidores } from "@/class/servidores.js";

import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";

import { Coordenador } from "@/class/coordenador.js";
import { Diretor } from "@/class/diretor.js";
import { Escola } from "@/class/escolas.js";

export default {
  props: {
    id:{},
    pg:{},
  },
  components: {

  },
  name: 'DiretorEditar',
  data () {
    return {
      escolasALL: [],
      escola: null,
      diretor: {
        nome:'',
        servidor_id: null,
        escola:'',
        ano:0,
        id:0,
      },

    }
  },
  methods: {
    async escolas(){
       let esc =  await Escola.obtemTodos();
       this.escolasALL = esc.data;
    },
    async buscarCalendarioAtual() {
      let data = await Calendario.calendarioAtual();
      this.diretor.ano = data.data[0].ano;
    },
    async editarOption(id){
      try {
        let data = await Diretor.obtemUm(id);

        let data1 = await Servidores.obtemUm(data.data.id.servidor_id);
        this.diretor.nome = data1.data[0].nome;
        this.diretor.servidor_id = data.data.id.servidor_id;
        for (const el of this.escolasALL) {
          if (el.id == data.data.id.escola) {
            this.escola = el.id;
            break;
          }
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async editar() {
      try {

        this.diretor.escola = this.escola;
        this.diretor.id = this.id;

        let data = await Diretor.alterar(this.diretor);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({name: 'coordenacao-servidores', params: { pg: this.pg}});
      }
      catch(e) {
        this.$vaToast.init({
          message: "Erro ao Editar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'danger',
          duration: 2500,
          fullWidth: false,
        })
      }
    },
  },
  mounted() {
    if (this.id == null) {
      this.$router.push({name: 'coordenacao-servidores'});
    }
    else {
      this.escolas();
      this.editarOption(this.id);
      this.buscarCalendarioAtual();
    }
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
